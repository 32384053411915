import React, { useEffect } from 'react'
import Portfoliogrid from './portfoliogrid'

const Portfolios = () => {
  useEffect(() => {
    
  })
  return (
    <section
      id='portfolio'
      name='portfolio'
      className='wspportfolios lg:pt-16 md:mt-0 w-full'
    >
      <h2 className='portfolio-heading text-center lg:text-right mx-auto lg:mr-12 px-0 lg:pr-7 text-fadeblack shadow-fadeblack'>
        ... Sample Works
      </h2>
      <div className='portfoliogallerycon w-full px-2 pt-4 lg:px-12 lg:pt-16 pb-24'>
        <Portfoliogrid />
      </div>
    </section>
  )
}

export default Portfolios
