import React from 'react'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// import 'swiper/swiper.scss'
// import 'swiper/components/navigation/navigation.scss'
// import 'swiper/components/pagination/pagination.scss'
import 'swiper/swiper-bundle.css'

import imgGatsby from '../../images/gatsby.jpg'
import imgShopify from '../../images/shopify.jpg'
import imgWP from '../../images/wordpress.jpg'

import blogimg from '../../images/Blog.jpg'
import personalbranding from '../../images/personalbranding.jpg'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

const PackagesSlides = () => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={3}
      loop={true}
      navigation
      pagination={{ clickable: true, dynamicBullets: true }}
      breakpoints={{
        123: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        375: {
          slidesPerView: 1.2,
          spaceBetween: 0
        },
        425: {
          slidesPerView: 1.4,
          spaceBetween: 0
        },
        477: {
          slidesPerView: 1.7,
          spaceBetween: 0
        },
        577: {
          slidesPerView: 1.9,
          spaceBetween: 0
        },
        712: {
          slidesPerView: 2.2,
          spaceBetween: 0
        },
        912: {
          slidesPerView: 2.7,
          spaceBetween: 0
        },
        1432: {
          slidesPerView: 3,
          spaceBetween: 0
        }
      }}
    >
      <SwiperSlide>
        <div className='flex-1 pricing-card-one fsd-p-card shadow-lg'>
          <p className='pricing-sentence p-4 m-0 text-xl bg-gray-700 text-white'>
            Business Begainer
          </p>
          <h3 className='fsd-pricing-t-1 fsd-pricing-t m-0'>
            <img className='m-auto' src={imgShopify} alt='' />
          </h3>
          <div className='pricing-price'>
            {/* <span className='pricing-currency'>$</span>999 */}
            <p className='text-left px-6 pt-4'>
              For the online store, Shopify is the most easily manageable yet
              powerful e-commerce focused content management system. We provide
              complete custom store design and setup with app/s integration
              focusing on your product identity.
            </p>
            <div className='px-6 py-6 block mb-3 text-left'>
              <a
                className='inline-block text-left px-12 py-2 border-solid border-2 border-gray-700 rounded hover:bg-gray-700 hover:text-white transition duration-200'
                href='#contact'
              >
                Send Query
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='flex-1 pricing-card-two fsd-p-card shadow-lg'>
          <p className='pricing-sentence p-4 text-xl bg-pink-700 text-white'>
            Business Advanced
          </p>
          <h3 className='fsd-pricing-t-2 fsd-pricing-t'>
            <img src={imgWP} alt='' />
          </h3>
          <div className='pricing-price'>
            <p className='text-left px-6 pt-4'>
              40% of the entire web uses WordPress, from hobby blogs to the
              biggest news sites online. Easy management and custom feature
              integration with tons of Plugins/Apps makes it the most popular
              website platform. For your custom solution, send a query to us.
            </p>
            <div className='px-6 py-6 block mb-3 text-left'>
              <a
                className='inline-block text-left px-12 py-2 border-solid border-2 border-gray-700 rounded hover:bg-gray-700 hover:text-white transition duration-200'
                href='#contact'
              >
                Send Query
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='flex-1 pricing-card-three fsd-p-card shadow-lg'>
          <p className='pricing-sentence p-4 text-xl bg-purple-700 text-white'>
            Headless CMS
          </p>
          <h3 className='fsd-pricing-t-3 fsd-pricing-t'>
            <img src={imgGatsby} alt='' />
          </h3>
          <div className='pricing-price'>
            <p className='text-left px-6 pt-4'>
              Gatsby is a modern Headless Flamework that behind the scene uses
              JAM (JavaScript API Markup) Stack. The frontend and backend are
              decoupled on a Headless Framework/System. Hence Gatsby is
              unbelievably fast, everything just feels...there.
            </p>
            <div className='px-6 py-6 block mb-3 text-left'>
              <a
                className='inline-block text-left px-12 py-2 border-solid border-2 border-gray-700 rounded hover:bg-gray-700 hover:text-white transition duration-200'
                href='#contact'
              >
                Send Query
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='flex-1 pricing-card-three fsd-p-card shadow-lg'>
          <p className='pricing-sentence p-4 text-xl bg-gray-700 text-white'>
            Customization & Optimization
          </p>
          <h3 className='fsd-pricing-t-3 fsd-pricing-t'>
            <img src={personalbranding} alt='' />
          </h3>
          <div className='pricing-price'>
            <p className='text-left px-6 pt-4'>
              If you're mostly satisfied with your overall design and just want
              some tweak or bugfix or wanna optimize in terms of SEO and Speed
              Performance. Write describing your requirements. We will try our
              best to slim down the cost.
            </p>
            <div className='px-6 py-6 block mb-3 text-left'>
              <a
                className='inline-block text-left px-12 py-2 border-solid border-2 border-gray-700 rounded hover:bg-gray-700 hover:text-white transition duration-200'
                href='#contact'
              >
                Send Query
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='flex-1 pricing-card-three fsd-p-card shadow-lg'>
          <p className='pricing-sentence p-4 text-xl bg-gray-700 text-white'>
            Digital Marketing
          </p>
          <h3 className='fsd-pricing-t-3 fsd-pricing-t'>
            <img src={blogimg} alt='' />
          </h3>
          <div className='pricing-price'>
            <p className='text-left px-6 pt-4'>
              Digital marketing is highly trackable and measurable through
              Analytics Software like Google Analytics. We provide GA
              Integration, SEO, and Adsense marketing on all major platforms
              like Facebook, Google, Twitter, Instagram..
            </p>
            <div className='px-6 py-6 block mb-3 text-left'>
              <a
                className='inline-block text-left px-12 py-2 border-solid border-2 border-gray-700 rounded hover:bg-gray-700 hover:text-white transition duration-200'
                href='#contact'
              >
                Send Query
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='flex-1 pricing-card-three fsd-p-card shadow-lg'>
          <p className='pricing-sentence p-4 text-xl bg-gray-700 text-white'>
            Monthly Maintanance
          </p>
          <h3 className='fsd-pricing-t-3 fsd-pricing-t'>
            <img src={personalbranding} alt='' />
          </h3>
          <div className='pricing-price'>
            <p className='text-left px-6 pt-4'>
              If your business needs dedicated developer support but does not
              have that much budget for a full-time team. We can help{' '}
              <span className='text-yellow-300'>&#9787;</span>. Send a message
              to our way with your custom requirements, budget, and some brief
              about your business.
            </p>
            <div className='px-6 py-6 block mb-3 text-left'>
              <a
                className='inline-block text-left px-12 py-2 border-solid border-2 border-gray-700 rounded hover:bg-gray-700 hover:text-white transition duration-200'
                href='#contact'
              >
                Send Query
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default PackagesSlides
