import React, { useEffect } from 'react'
// import anime from 'animejs'
// if (typeof window !== 'undefined') {
// import * as ScrollMagic from 'scrollmagic'
// import 'debug.addIndicators'
// }
import PackagesSlides from './PackagesSlides'
import imgGatsby from '../../images/gatsby.jpg'
import imgShopify from '../../images/shopify.jpg'
import imgWP from '../../images/wordpress.jpg'

import blogimg from '../../images/Blog.jpg'
import personalbranding from '../../images/personalbranding.jpg'
import anime from 'animejs'
// import 'swiper/swiper-bundle.css'

import SlidingClipping from '../../images/inlinesvgs/slidingtextclippath.svg'
import WspLaptop from '../../images/inlinesvgs/wsplaptop.svg'
import RocketAnimation from '../homepageComponent/RocketAnimation'

// import SwiperCore, { Navigation, Pagination } from 'swiper/core'

// configure Swiper to use modules
// SwiperCore.use([Navigation, Pagination])

const Packages = () => {
  useEffect(() => {
    //Scroll Magic

    if (typeof window !== 'undefined') {
      // import('scrollmagic').then(ScrollMagic => {
      //   import('debug.addIndicators').then(
      //     addIndicators => {
      const ScrollMagic = require('scrollmagic')
      // require('debug.addIndicators')
      var clippingpulse = anime({
        targets: '#slidingpathsm',
        duration: 3000,
        d: [
          'M 889.23 642.75 C 859.333 656.75 262.143 696.083 194.404 642.75 C 126.665 589.417 -31.56 449.132 165.228 303.495 C 207.161 273.648 218.858 267.15 391.533 253.821 C 529.746 243.613 558.599 192.006 730.007 121.167 C 730.007 121.167 868.744 54.558 924.335 161.811 C 979.928 269.061 849.718 316.105 957.297 504.265 C 1007.148 609.636 919.128 628.75 889.23 642.75 Z',
          'M 889.23 642.75 C 797.333 670.75 251.476 686.75 194.404 642.75 C 137.332 598.75 -123.486 367.299 73.301 221.662 C 115.234 191.815 225.54 208.998 398.215 195.669 C 536.428 185.461 554.32 111.719 725.728 40.881 C 725.728 40.881 907.974 -55.846 963.565 51.407 C 1019.159 158.657 900.174 318.126 1007.753 506.286 C 1057.606 611.657 981.128 614.75 889.23 642.75 Z'
        ],
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutQuad'
      })
      clippingpulse.pause()
      //package animation
      var pkgctrl = new ScrollMagic.Controller()
      var pkgTriggerElem = document.querySelector('.flexible-packages')
      var pkgScene = new ScrollMagic.Scene({
        triggerElement: pkgTriggerElem,
        offset: -147,
        triggerHook: 'onCenter'
      })
        .addTo(pkgctrl)
        // .addIndicators()
        .on('progress', function (event) {
          pkgTriggerElem &&
            !pkgTriggerElem.classList.contains('flexible-package-start') &&
            pkgTriggerElem.classList.add('flexible-package-start')
          clippingpulse.pause()
          pkgScene.remove()
        })

      // Swiper Gallery
      var swiperIntrance = anime.timeline({
        easing: 'easeOutQuad',
        duration: 900,
        loop: 1,
        easing: 'cubicBezier( 0.29, 0.01, 0.79, 0.99 )',
        autoplay: false
      })
      swiperIntrance.add({
        targets: '.fsd-pricing-section',
        translateY: ['20px', '0px'],
        opacity: ['0', '1']
      })
      var swiperTriggerElem = document.querySelector('.fsd-pricing-section')
      var swiperScene = new ScrollMagic.Scene({
        triggerElement: swiperTriggerElem,
        offset: -214,
        triggerHook: 'onCenter'
      })
        .addTo(pkgctrl)
        // .addIndicators()
        .on('progress', function (event) {
          swiperIntrance.play()
          swiperScene.remove()
        })

      // WSP Sliding Text 1

      let labels = document.getElementsByClassName('labels'),
        rocket = document.querySelectorAll('.rocket'),
        smokeL = document.querySelectorAll('.rocket__smoke--left'),
        smokeR = document.querySelectorAll('.rocket__smoke--right'),
        fire = document.getElementsByClassName('rocket__fire')

      var slidingIntrance = anime.timeline({
        easing: 'easeOutQuad',
        loop: 1,
        easing: 'easeInOutQuad',
        autoplay: false
      })
      slidingIntrance
        .add({
          targets: '.wsp-sliding-bg',
          duration: 1400,
          translateX: (el, i) => {
            console.log(i)
            return i % 2 === 0 ? ['123%', '-123%'] : ['-123%', '123%']
          },
          // scaleX: [{ value: 1, duration: 1000, delay: 0  }, { value: 4, duration: 1000, delay: 0 }, { value: 1, duration: 1000, delay: 0  }],
          delay: anime.stagger(400),
          easing: 'linear'
        })
        .add(
          {
            targets: '.wsp-sliding-text-1',
            translateX: (el, i) => {
              console.log(i)
              return i % 2 === 0 ? ['4%', '0%'] : ['-4%', '0%']
            },
            opacity: ['0', '1'],
            letterSpacing: ['-14', '0'],
            delay: anime.stagger(400),
            easing: 'easeInOutQuart'
          },
          '-=1400'
        )
        .add(
          {
            targets: '.s2-right-con',
            opacity: ['0', '1'],
            translateY: ['20', '0'],
            duration: 700
          },
          '-=700'
        )
        .add({
          targets: labels,
          duration: 1200,
          keyframes: [
            { scale: 0, translateX: 100, translateY: 100, opacity: 0 },
            { scale: 1, opacity: 1, translateX: 0, translateY: 0 },
            { scale: 1, opacity: 0, translateX: -50, translateY: -50 }
          ],
          delay: anime.stagger(400, { direction: 'reverse' })
        })
        .add(
          {
            duration: 1400,
            changeBegin: function () {
              anime({
                targets: rocket,
                keyframes: [
                  { translateX: -4 },
                  { translateX: 4 },
                  { translateX: 0 }
                ],
                duration: 40,
                direction: 'alternate',
                loop: 30,
                easing: 'easeOutBack'
              })
            }
          },
          '-=40'
        )

        .add(
          {
            targets: '.rocket__fire',
            scale: ['0', '1'],
            rotate: ['135', '135'],
            duration: 1000,
            changeComplete: () => {
              anime({
                targets: '.rocket__fire',
                scale: ['3', '0'],
                loop: 3,
                duration: 300,
                direction: 'alternate',
                changeComplete: () => {
                  anime({
                    targets: '.rocket__fire',
                    scale: ['0', '3'],
                    loop: 2,
                    duration: 300
                  })
                }
              })
            }
          },
          '-=1400'
        )
        .add(
          {
            targets: smokeL,
            scale: [
              { value: 0, duration: 0 },
              { value: 2, duration: 1400 }
            ],
            opacity: [
              { value: 0, duration: 0 },
              { value: 0.7, duration: 1000 },
              { value: 0, duration: 400 }
            ],
            translateX: [
              { value: 0, duration: 0 },
              { value: -20, duration: 1000 }
            ],
            rotateY: [{ value: 180 }],
            delay: anime.stagger(100, { direction: 'reverse' }),
            easing: 'cubicBezier(0.04, 0.04, 0.73, -0.09)'
          },
          '-=2100'
        )
        .add(
          {
            targets: smokeR,
            scale: [
              { value: 0, duration: 0 },
              { value: 2, duration: 1400 }
            ],
            opacity: [
              { value: 0, duration: 0 },
              { value: 0.7, duration: 1000 },
              { value: 0, duration: 400 }
            ],
            translateX: [
              { value: 0, duration: 0 },
              { value: 20, duration: 1000 }
            ],
            delay: anime.stagger(100, { direction: 'reverse' }),
            easing: 'cubicBezier(0.04, 0.04, 0.73, -0.09)'
          },
          '-=2300'
        )
        .add(
          {
            targets: rocket,
            translateY: [`0`, `-${document.body.offsetHeight}`],
            duration: 1000,
            opacity: [
              { value: 1, duration: 0 },
              { value: 1, duration: 1000 },
              { value: 0, duration: 400 }
            ],
            easing: 'easeInExpo'
          },
          '+=100'
        )
        .add({
          targets: rocket,
          translateY: 0,
          duration: 700,
          easing: 'easeInExpo',
          opacity: [
            { value: 0, duration: 0 },
            { value: 0, duration: 700 },
            { value: 1, duration: 700 }
          ],
          complete: () => {
            clippingpulse.play()
          }
        })

      var slidingTriggerElem = document.querySelector('.s2-bottom-text')
      var slidingScene = new ScrollMagic.Scene({
        triggerElement: slidingTriggerElem,
        offset: 0,
        triggerHook: 'onCenter'
      })
        .addTo(pkgctrl)
        // .addIndicators()
        .on('progress', function (event) {
          slidingIntrance.play()
          slidingScene.remove()
        })
      //     } // addindicator ends
      //   )
      // })

      //Portfolio Intrance
      var portfolioIntrance = anime.timeline({
        easing: 'easeOutQuad',
        duration: 900,
        loop: 1,
        easing: 'cubicBezier( 0.29, 0.01, 0.79, 0.99 )',
        autoplay: false
      })

      portfolioIntrance.add({
        targets: ['.wspportfolios .portfolio-heading', '.wspportfolios .portfoliogallerycon'],
        translateY: ['20px', '0px'],
        opacity: ['0', '1'],
        delay: anime.stagger(400)
      })

      var portfolioTriggerElem = document.querySelector('.wspportfolios')
      var portfolioScene = new ScrollMagic.Scene({
        triggerElement: portfolioTriggerElem,
        offset: -214,
        triggerHook: 'onCenter'
      })
        .addTo(pkgctrl)
        // .addIndicators()
        .on('progress', function (event) {
          portfolioIntrance.play()
          portfolioScene.remove()
        })
    } //window != undefined
  })
  return (
    <div
      id='packageswsp'
      name='packageswsp'
      className='text-center pt-7 lg:pt-12 xl:pt-24'
    >
      <h2 className='flexible-packages shadow-fadeblack'>
        <div>
          <span className='inline-block fsd-s2-h-left'>Flexible</span>
        </div>
        <div>
          <span className='inline-block fsd-s2-h-right'>Packages</span>
        </div>
      </h2>

      <section className='fsd-pricing-section lg:mt-16 md:mt-0 fsd-con md:px-4 lg:px-12'>
        <PackagesSlides />
      </section>

      <div className='s2-bottom-text mb-16 xl:mb-32 flex flex-col xl:flex-row w-full'>
        <div className='s2-left-con px-4 xl:pl-7 xl:pr-0 xl:pb-0 py-6 ml-0 xl:ml-12 w-full xl:w-4/6 flex flex-col justify-end'>
          <h2 className='text-center xl:text-left relative overflow-hidden'>
            <div className='wsp-sliding-text-1 text-fadeblack'>
              Get the Fast &
            </div>
            <div className='wsp-sliding-bg absolute bg-fadeblack left-0 top-0 w-full h-full'></div>
          </h2>
          <h2 className='text-center xl:text-left mt-4 relative overflow-hidden'>
            <div className='wsp-sliding-text-1 text-fadeblack'>
              Optimized Solution
            </div>
            <div className='wsp-sliding-bg absolute bg-fadeblack left-0 top-0 w-full h-full'></div>
          </h2>
          <h2 className='text-center xl:text-left mt-4 relative overflow-hidden'>
            <div className='wsp-sliding-text-1 text-fadeblack'>
              Out of the Box.
            </div>
            <div className='wsp-sliding-bg absolute bg-fadeblack left-0 top-0 w-full h-full'></div>
          </h2>
        </div>
        <div className='s2-right-con px-4 pt-12 pb-6 mb-0 xl:mb-6 xl:mr-12 w-full lg:w-3/4 mx-auto xl:w-2/6 relative'>
          <div className='s2-bg-clipped w-full h-full absolute left-0'></div>
          <SlidingClipping />
          <div className='wsp-laptop absolute w-full px-4 lg:px-0'>
            <WspLaptop />
          </div>
          <div className='wsp-rocket absolute w-full'>
            <RocketAnimation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Packages
