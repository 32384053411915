import React from 'react'

const RocketAnimation = () => {
    return (
        <>
             <div id="wsprocketcon">
      <div className="rocket">
        <div className="rocket__body">
          <div className="rocket__body__window">
            <div className="shadow"></div>
          </div>
          <div className="rocket__body__inner">
            <div className="shadow"></div>
          </div>
        </div>
        <div className="rocket__wing rocket__wing--left"></div>
        <div className="rocket__wing rocket__wing--right">
          <div className="shadow shadow--full"></div>
        </div>
        <div className="rocket__label">
          <p className="labels">3</p>
          <p className="labels">2</p>
          <p className="labels">1</p>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--left">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__smoke rocket__smoke--right">
          <div className="rocket__smoke__inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="rocket__fire"></div>
      </div>
    </div>   
        </>
    )
}

export default RocketAnimation