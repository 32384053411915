import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
export const Bgintro = ({children, ...props}) => {
    const data = useStaticQuery(
      graphql`
        query {
          desktop: file(relativePath: { eq: "wspbackground.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    )

    const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag='div'
      fluid={imageData}    
      {...props}
    >
      {children}
    </BackgroundImage>
  )
}
