import React from 'react'
import { Helmet } from 'react-helmet'
// import Layout from "../components/layout"
import SEO from '../components/seo'
import Intro from '../components/homepageComponent/Intro'
import Packages from '../components/homepageComponent/Packages'
import Portfolios from '../components/homepageComponent/Portfolios'
// import WhatYouWillGet from '../components/homepageComponent/WhatYouWillGet'
// import Recommendations from '../components/homepageComponent/Recommendations'
// import Portfolios from '../components/homepageComponent/Portfolios'
// import CustomWebDesingAnalysis from '../components/homepageComponent/CustomWebDesingAnalysis'
// import ContactForm from '../components/homepageComponent/ContactForm'
// import BrandsLogos from '../components/homepageComponent/BrandsLogos'
// import WhyChooseUs from '../components/homepageComponent/WhyChooseUs'

import {Socialcard} from '../components/homepageComponent/bgcomponents/Socialcard'

const Index = () => {
  return (
    <>
      <SEO title='Creative Agency' image={Socialcard()} pathname='https://websolverpro.com' />
      <Helmet>
        <body className='index-page' />
      </Helmet>

      <Intro />
      <Packages />
      <Portfolios />
    </>
  )
}

export default Index
